import { RenderType, TypeRenderer } from '@graphcommerce/next-ui'
import dynamic from 'next/dynamic'
import { RowBlogContent } from '../Blog'
import { BestSellers } from '../Home/BestSellers'
import { HeroBannerIcons } from '../Home/HeroBannerIcons'
import { HomePageFaqs } from '../Home/HomePageFaqs'
import { NewItems } from '../Home/NewItems'
import { WhyUseTempli } from '../Home/WhyUseTempli'
import { PageContentQueryFragment } from './PageContentQueryFragment.gql'
import { RowBrandsBanner } from './RowBrandsBanner/RowBrandsBanner'
import { RowHeroBanner } from './RowHeroBanner/RowHeroBanner'
import { RowOrderProcess } from './RowOrderProcess/RowOrderProcess'
import { RowProduct } from './RowProduct/RowProduct'
import { RowProductHighlight } from './RowProductHighlight/RowProductHighlight'
import { RowRendererFragment } from './RowRenderer.gql'
import { RowReviewHighlight } from './RowReviewHighlights/RowReviewHighlight'
import { RowServiceOptions } from './RowServiceOptions/RowServiceOptions'
import { RowServicePageContent } from './RowServicePageConent/RowServicePageContent'

type ContentTypeRenderer = TypeRenderer<PageContentQueryFragment['pages'][0]['content'][0]>

const defaultRenderer: Partial<ContentTypeRenderer> = {
  RowHeroBanner,
  RowBlogContent,
  RowServiceOptions,
  RowProduct,
  RowProductHighlight,
  RowBrandsBanner,
  RowServicePageContent,
  RowReviewHighlight,
  RowOrderProcess,
}

export type PageProps = RowRendererFragment & {
  renderer?: Partial<ContentTypeRenderer>
}

export function RowRenderer(props: PageProps) {
  const { content, renderer } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as ContentTypeRenderer
  const withNoSSR = (Component: React.FunctionComponent) =>
    dynamic(() => Promise.resolve(Component), { ssr: false })

  // const BestSellersWithNoSSR = withNoSSR(() => (
  //   <BestSellers homePageComponent noHorizontalMargins />
  // ))
  // const NewItemsWithNoSSR = withNoSSR(() => <NewItems homePageComponent noHorizontalMargins />)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className='bg-lightest-gray'>
      {content?.map((item, idx) => (
        <>
          {idx === 1 && content?.length > 1 && <HeroBannerIcons />}{' '}
          {/* {idx === 3 && <BestSellersWithNoSSR />} */}
          {/* {idx === 3 && content?.length > 1 && <NewItemsWithNoSSR />} */}
          {idx === 4 && content?.length > 1 && <WhyUseTempli />}
          {idx === 4 && content?.length > 1 && <HomePageFaqs />}
          <RenderType key={item.id} renderer={mergedRenderer} {...item} />
        </>
      ))}
    </div>
  )
}
