import { Image } from '@graphcommerce/image'
import { ContainerProps } from '@mui/material'
import React from 'react'

export type ImageProps = {
  id: string
  image: {
    url: string
    alt?: string | null | undefined
  }
  isMobile?: boolean | null | undefined
}

export type HeroBannerProps = ContainerProps & {
  banners: ImageProps[]
  pageLinks: React.ReactNode
  headerTitle?: string | null
  headerText?: string | null
}

export function HeroBanner(props: HeroBannerProps) {
  const { banners, pageLinks, headerText, headerTitle, children, ...containerProps } = props
  const desktopBanner = banners && banners?.filter((item) => !item.isMobile)
  const mobileBanner = banners && banners?.filter((item) => item.isMobile)

  return (
    <div {...containerProps} className='flex w-full items-center justify-center'>
      <div className='justify-cente flex w-full max-w-maxContentWidth items-center '>
        <div className='relative block min-h-[350px] min-w-full bg-concept-print-lightgray'>
          {desktopBanner && desktopBanner?.length > 0 && (
            <div className='hidden md:block'>
              <Image
                src={desktopBanner[0].image.url}
                alt={desktopBanner[0].image.alt || ''}
                height={400}
                layout='intrinsic'
                className='object-contain'
                loading='eager'
              />
            </div>
          )}
          {mobileBanner && mobileBanner?.length > 0 && (
            <div className='block md:hidden'>
              <Image
                src={mobileBanner[0].image.url}
                alt={mobileBanner[0].image.alt || ''}
                height={400}
                layout='intrinsic'
                className='object-contain'
                loading='eager'
              />
            </div>
          )}
          <div className='absolute left-0 top-0 h-full w-full bg-transparent md:w-2/3 lg:ml-10 lg:w-[60%]'>
            {children}
            {pageLinks}
          </div>
        </div>
      </div>
    </div>
  )
}
