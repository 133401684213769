import { i18n } from '@lingui/core'
import { Rating } from '@mui/material'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import notRecommend from '../../assets/images/thumbs_down.svg'
import recommend from '../../assets/images/thumbs_up.svg'
import useCallAPI from '../../hooks/useCallAPI'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'
import { Spinner } from '../Spinner'
import { ReviewItemImageList } from './ReviewItemImageList'

export type ReviewUserProps = {
  user_id: number
  display_name: string
  social_image: string
  user_type: string
  is_social_connected: number
  customer?: any | undefined
}

export type ReviewImageProps = {
  thumb_url: string
  original_url: string
}

export type ReviewItemProps = {
  id: number
  title?: string
  content: string
  score: number
  votes_up?: number
  votes_down?: number
  created_at: string
  sentiment?: number
  verified_buyer?
  user?: ReviewUserProps
  images_data: ReviewImageProps[]
  customer: any | undefined
  displayVotes?: boolean
  displayImages?: boolean
  displayAvatar?: boolean
  avatar_url?: string
  userName?: string | null | undefined
  displayDate?: boolean
  isHomePageItem?: boolean
  comment?: any | undefined
  displayReplies?: boolean
  companyName?: string | null | undefined
}

export function ReviewItem(props: ReviewItemProps) {
  const {
    created_at,
    id,
    verified_buyer,
    user,
    images_data,
    title = '',
    content,
    votes_up,
    votes_down,
    sentiment,
    customer,
    displayVotes = false,
    displayImages = true,
    displayAvatar = false,
    displayReplies = true,
    avatar_url,
    userName,
    displayDate = true,
    isHomePageItem,
    comment,
    companyName,
  } = props
  const lastUpdateDate = new Date(created_at)
  const numOfDaysPosted = Math.ceil(
    // eslint-disable-next-line no-unsafe-optional-chaining
    lastUpdateDate ? (Date.now() - lastUpdateDate?.getTime()) / 86400000 : 0,
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const commentDate = new Date(comment?.created_at)
  const commentNumOfDaysPosted = Math.ceil(
    // eslint-disable-next-line no-unsafe-optional-chaining
    commentDate ? (Date.now() - commentDate?.getTime()) / 86400000 : 0,
  )

  const isUserLoggedIn = !!customer
  const {
    error,
    apiCall: voteUpApiCall,
    loading: voteUpLoading,
  } = useCallAPI({
    url: `${process.env.NEXT_PUBLIC_YOTPO_BASE_URL}/reviews/${id}/vote/up`,
    contentType: 'application/json',
    method: 'POST',
  })

  const {
    error: voteDownError,
    apiCall: voteDownApiCall,
    loading: voteDownLoading,
  } = useCallAPI({
    url: `${process.env.NEXT_PUBLIC_YOTPO_BASE_URL}/reviews/${id}/vote/down`,
    contentType: 'application/json',
    method: 'POST',
  })

  const {
    error: undoVoteUpApiCallError,
    apiCall: undoVoteUpApiCall,
    loading: undoVoteUpLoading,
  } = useCallAPI({
    url: `${process.env.NEXT_PUBLIC_YOTPO_BASE_URL}/reviews/${id}/vote/up/true`,
    contentType: 'application/json',
    method: 'POST',
  })

  const {
    error: undoVoteDownError,
    apiCall: undoVoteDownApiCall,
    loading: undoVoteDownLoading,
  } = useCallAPI({
    url: `${process.env.NEXT_PUBLIC_YOTPO_BASE_URL}/reviews/${id}/vote/down/true`,
    contentType: 'application/json',
    method: 'POST',
  })

  const [votesUp, setVotesUp] = useState<number>(votes_up ?? 0)
  const [hasLeftVoteUp, setHasLeftVoteUp] = useState<boolean>(false)

  const [votesDown, setVotesDown] = useState<number>(votes_down ?? 0)
  const [hasLeftVoteDown, setHasLeftVoteDown] = useState<boolean>(false)
  const [isDisplayAlertPrompt, setisDisplayAlertPrompt] = useState<boolean>(false)
  const [isDisplayErrorPrompt, setIsDisplayErrorPrompt] = useState<boolean>(false)
  const [decodedContent, setDecodedContent] = useState<any>(false)

  const handleVoteUp = async () => {
    if (!isUserLoggedIn) {
      setIsDisplayErrorPrompt(true)
      return
    }
    if (!hasLeftVoteUp && !hasLeftVoteDown) {
      const res = await voteUpApiCall()
      if (res?.status === 200) {
        setHasLeftVoteUp(true)
        setVotesUp(votesUp + 1)
        setisDisplayAlertPrompt(true)
      }
    }
    if (hasLeftVoteDown) {
      const res = await voteUpApiCall()
      if (res?.status === 200) {
        const undoRes = await undoVoteDownApiCall()
        if (undoRes?.status === 200) {
          setHasLeftVoteDown(false)
          setHasLeftVoteUp(true)
          setVotesUp(votesUp + 1)
          setVotesDown(votesDown - 1)
          setisDisplayAlertPrompt(true)
        }
      }
    }
  }
  const handleVoteDown = async () => {
    if (!isUserLoggedIn) {
      setIsDisplayErrorPrompt(true)
      return
    }
    if (!hasLeftVoteUp && !hasLeftVoteDown) {
      const res = await voteDownApiCall()
      if (res?.status === 200) {
        setHasLeftVoteDown(true)
        setVotesDown(votesDown + 1)
        setisDisplayAlertPrompt(true)
      }
    }
    if (hasLeftVoteUp) {
      const res = await voteDownApiCall()
      if (res?.status === 200) {
        const undoRes = await undoVoteUpApiCall()
        if (undoRes?.status === 200) {
          setHasLeftVoteDown(true)
          setHasLeftVoteUp(false)
          setVotesDown(votesDown + 1)
          setVotesUp(votesUp - 1)
          setisDisplayAlertPrompt(true)
        }
      }
    }
  }

  const decodeHtml = (text) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = text
    return txt.value
  }

  useEffect(() => {
    setDecodedContent(decodeHtml(content))
  }, [content])

  const logoInitialsArry = user?.display_name?.split(' ')
  const firstInitial = logoInitialsArry?.at(0)?.toUpperCase().charAt(0)
  const secondInitial = logoInitialsArry?.at(1)?.toUpperCase().charAt(0)

  const logoInitials =
    firstInitial && firstInitial?.length > 0
      ? `${firstInitial ?? ''}${secondInitial ?? ''}`
      : userName?.charAt(0)?.toUpperCase()
  return (
    <div
      key={id}
      className={`mb-[20px] gap-x-10 gap-y-5 py-1 ${
        isHomePageItem
          ? 'rounded-md border-[1px] border-20-grey bg-pure-white px-5 py-4 lg:block'
          : 'lg:grid lg:grid-cols-[70fr_30fr]'
      }`}
    >
      <div className={`grid ${isHomePageItem ? 'grid-cols-1' : 'grid-cols-[8fr_92fr]'}`}>
        {isHomePageItem ? (
          <div />
        ) : (
          <div className='relative flex h-[30px] w-[30px] items-center justify-center rounded-[50%] bg-medium-gray'>
            <span className='Type-XL-Medium text-pure-white'>{logoInitials ?? ''}</span>
          </div>
        )}
        <div className='mb-[10px] lg:mb-0'>
          <div className='Type-Base-Regular flex justify-start pb-2'>
            <Rating
              name='size-small'
              defaultValue={props?.score}
              size='small'
              readOnly
              precision={0.5}
              className='scale-90 align-bottom'
            />
            <div
              className={`${
                isHomePageItem ? 'Type-Large-Bold' : 'Type-Large-Medium'
              } ml-2 flex items-center gap-1 pb-2 text-black`}
            >
              {user?.display_name ?? userName}
              {companyName && <span>{`, ${companyName}`}</span>}
              {displayDate && <span>&#8729;</span>}
              {displayDate && <span className='pr-2'>{numOfDaysPosted} days ago</span>}
            </div>
          </div>

          <p className='Type-XL-Regular inline pb-2 text-70-grey'>{decodedContent}</p>
          {/* {images_data?.length > 0 && (
          <ReviewItemImageList images_data={images_data} className='my-5 lg:hidden' />
        )} */}
          {displayVotes && (
            <p className='flex items-center pt-2'>
              <span className='Type-XL-Bold inline-block items-center pr-2 text-50-grey'>
                {i18n._('Was this review helpful?')}
              </span>
              {voteUpLoading || voteDownLoading || undoVoteUpLoading || undoVoteDownLoading ? (
                <Spinner className='' color='purple' />
              ) : (
                <Image
                  src={recommend}
                  alt='Recommend'
                  className='scale-75 cursor-pointer hover:scale-90 active:scale-100'
                  unoptimized
                  onClick={() => handleVoteUp()}
                />
              )}
              <span className='pl-[4px] pr-3 text-70-grey'>{votesUp}</span>
              {voteUpLoading || voteDownLoading || undoVoteUpLoading || undoVoteDownLoading ? (
                <Spinner className='' color='purple' />
              ) : (
                <Image
                  className='scale-75 cursor-pointer hover:scale-90 active:scale-100'
                  src={notRecommend}
                  alt='Not Recommend'
                  unoptimized
                  onClick={() => handleVoteDown()}
                />
              )}
              <span className='pl-[4px] text-70-grey'>{votesDown}</span>
            </p>
          )}
        </div>
      </div>
      {displayImages && (
        <div>
          {images_data?.length > 0 && (
            <ReviewItemImageList images_data={images_data} className='my-5 hidden lg:flex' />
          )}
        </div>
      )}
      {displayAvatar && (
        <div className='hidden w-full items-start justify-end lg:flex'>
          <Image
            src={avatar_url ?? ''}
            alt='user_brand'
            unoptimized
            className='aspect-square h-auto w-[150px] rounded-[50%]'
            width={150}
            height={150}
          />
        </div>
      )}
      {displayReplies && comment?.content && (
        <div className='ml-[8%] border-l-[4px] border-50-grey p-[20px] text-70-grey'>
          <span className='Type-Base-Regular pr-2 text-parks-green'>
            {commentNumOfDaysPosted} days ago
          </span>
          <span className='Type-Large-Bold flex items-center pb-2 text-templi-purple'>
            {i18n._('Templi says:')}
          </span>
          <span className='Type-XL-Regular'>{comment?.content}</span>
        </div>
      )}
      <AlertPrompt
        title={i18n._('Thank you')}
        isDisplayed={isDisplayAlertPrompt}
        setIsDisplayed={setisDisplayAlertPrompt}
        message={i18n._('Thank you for leaving a feedback!')}
      />
      <AlertPrompt
        title={i18n._('Oops!')}
        isDisplayed={isDisplayErrorPrompt}
        setIsDisplayed={setIsDisplayErrorPrompt}
        message={i18n._('You must be logged in to leave a feedback for a review.')}
      />
      <ErrorPrompt error={error ?? voteDownError ?? undoVoteUpApiCallError ?? undoVoteDownError} />
    </div>
  )
}
