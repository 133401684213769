import { ProductItemsGridProps, AddProductsToCartForm } from '@graphcommerce/magento-product'
import { ProductListItemsBase } from '../Product/ProductListItem/ProductListItemBase'
import { productListRenderer } from './productListRenderer'

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'> & {
  columns?: number
}

export function ProductListItems(props: ProductListItemsProps) {
  return (
    <AddProductsToCartForm>
      <ProductListItemsBase renderers={productListRenderer} {...props} />
    </AddProductsToCartForm>
  )
}
