import { Skeleton } from '@mui/material'
import PageLink from 'next/link'
import { Button } from '../../Button'
import { HeroBanner } from './HeroBanner'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { banners, headerText, pageLinks, headerTitle } = props
  if (!props?.banners) {
    return (
      <>
        <div className='hidden md:block'>
          <Skeleton
            variant='rectangular'
            sx={{ height: '370px', width: '100%' }}
            animation='pulse'
          />
        </div>
        <div className='block md:hidden'>
          <Skeleton
            variant='rectangular'
            sx={{ height: '500px', width: '100%' }}
            animation='pulse'
          />
        </div>
      </>
    )
  }
  return (
    <HeroBanner
      pageLinks={pageLinks.map((pageLink, index) => (
        <PageLink key={pageLink.url} href={pageLink.url} passHref>
          <Button
            label={pageLink.title}
            color='orange'
            type='button'
            borderColor='none'
            className='Type-XL-Medium m-[5%] px-2 py-1 md:ml-[5%] md:mt-0 md:px-3 md:py-3 lg:px-5 lg:py-4'
          />
        </PageLink>
      ))}
      headerText={headerText}
      headerTitle={headerTitle}
      banners={banners}
    >
      <h2 className='xl:Type-H2-Regular md:Type-H3-Regular lg:Type-H2-Regular Type-XXXL-Bold ml-[5%] flex h-1/2 items-center text-concept-print-orange md:m-[5%]'>
        {headerText}
      </h2>
    </HeroBanner>
  )
}
