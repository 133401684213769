import { ProductSpecsFragment } from '@graphcommerce/magento-product/components/ProductSpecs/ProductSpecs.gql'
import { ProductSpecs } from '../../../Product/ProductSpecs/ProductSpecs'
import { RowProductFragment } from '../RowProduct.gql'

type SpecsProps = RowProductFragment & ProductSpecsFragment

export function Specs(props: SpecsProps) {
  const { aggregations } = props

  if (!aggregations) return null

  return <ProductSpecs aggregations={aggregations} />
}
