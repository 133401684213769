import { useQuery } from '@graphcommerce/graphql'
import { i18n } from '@lingui/core'
import { Paper } from '@mui/material'
import { AmFAQCategoryDocument } from '../../graphql/AmFAQCategory.gql'
import { ProductQuestionsAndAnswers } from '../Reviews/ProductQuestionsAndAnswers'

export const homePageFaqUrlKey = 'home'

export function HomePageFaqs() {
  const homePageFAQs =
    useQuery(AmFAQCategoryDocument, {
      fetchPolicy: 'cache-and-network',
      variables: {
        pageSize: 100,
      },
    })?.data?.searchAmFaqCategories?.items ?? []

  const qaData =
    homePageFAQs.filter((item) => item?.url_key === homePageFaqUrlKey)?.at(0)?.questions ?? []

  return (
    <div className='flex w-full items-center justify-center bg-pure-white'>
      <Paper
        elevation={0}
        className='flex w-full max-w-maxContentWidth flex-col rounded-md px-[20px] py-6 md:px-[60px]'
      >
        <h2 className='mb-8 text-[30px] '>
          <span className='Type-XXL-Regular lg:Type-H2-Regular md:Type-H4-Regular text-concept-print-orange'>
            {i18n._('Commonly asked questions')}
          </span>
        </h2>
        <ProductQuestionsAndAnswers qaData={qaData ?? []} displayInTab={false} displayInHomePage />
      </Paper>
    </div>
  )
}
