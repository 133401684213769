import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { useState } from 'react'
import arrowLeft from '../../../assets/images/arrow-left.svg'
import arrowRight from '../../../assets/images/arrow-right.svg'
import useWindowSize from '../../../hooks/useWindowSize'
import { RowBrandsBannerFragment } from './RowBrandsBanner.gql'

export type BrandProps = {
  src: string
  url: string
  alt: string
}

type RowBrandsBannerProps = RowBrandsBannerFragment

export function RowBrandsBanner(props: RowBrandsBannerProps) {
  const { brands } = props
  const { isMobile } = useWindowSize()
  const [isPlaying, setIsPlaying] = useState(true)
  return (
    <div className='flex items-center justify-center bg-concept-print-lightgray/5 pt-5'>
      <div className='mx-[20px] w-full max-w-maxContentWidth'>
        <h2 className='mb-8 text-[30px] '>
          <span className='Type-XXL-Regular lg:Type-H2-Regular md:Type-H4-Regular text-concept-print-orange md:mx-[40px]'>
            {i18n._('Companies That Trust Us')}
          </span>
        </h2>
        <div className='mb-5 w-full lg:mb-10'>
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={100}
            totalSlides={brands.length}
            interval={5000}
            isPlaying={isPlaying}
            step={2}
            visibleSlides={isMobile ? 4 : 6}
            infinite
            isIntrinsicHeight
            className='grid w-full grid-cols-[5fr_90fr_5fr] self-center'
          >
            <ButtonBack className='flex items-center justify-center justify-self-start hover:scale-110'>
              <Image src={arrowLeft} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonBack>
            <Slider
              onMouseEnter={() => setIsPlaying(false)}
              onMouseLeave={() => setIsPlaying(true)}
            >
              {brands?.map((brand, index) => (
                <Slide
                  key={brand.id}
                  index={index}
                  className=' mx-2 flex items-center justify-center justify-self-start md:!mx-8 lg:mx-5'
                  innerClassName='flex items-center justify-center '
                >
                  <a href={brand.url || '#'} target='_blank' rel='noreferrer'>
                    <Image
                      src={brand.image?.url || '/'}
                      alt={brand.alt || ''}
                      layout='fixed'
                      height={200}
                      className='scale-150 md:scale-[200%]'
                    />
                  </a>
                </Slide>
              ))}
            </Slider>
            <ButtonNext className='flex items-center justify-center justify-self-end hover:scale-110'>
              <Image src={arrowRight} alt='Back' layout='fixed' unoptimized height={20} />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </div>
  )
}
