import { Image } from '@graphcommerce/image'
import useWindowSize from '../../../hooks/useWindowSize'
import { RowOrderProcessFragment } from './RowOrderProcess.gql'

type RowOrderProcessProps = RowOrderProcessFragment

export function RowOrderProcess(props: RowOrderProcessProps) {
  const { orderSteps, labelDesktop, labelMobile } = props
  const { isMobile, isTablet } = useWindowSize()
  const orderStepsArr = orderSteps.filter((element) => !element.isMobile)
  const label = labelDesktop
  return (
    <div className='flex w-full items-center justify-center bg-concept-print-white '>
      <div className='mb-5 w-full max-w-maxContentWidth px-2 md:mx-5 md:mb-10'>
        <div className='flex flex-row items-center justify-items-center gap-x-2 gap-y-20'>
          <span className='Type-XXL-Regular lg:Type-H2-Regular md:Type-H4-Regular mx-4 w-1/4 flex-shrink text-concept-print-orange'>
            {label}
          </span>
          <div className='flex w-full flex-row items-center justify-center gap-x-2 gap-y-20'>
            {orderStepsArr.map((orderStep) => (
              <div
                key={orderStep.id}
                className='grid-row grid min-w-[calc(25%-60px)] items-center justify-items-center gap-y-[20px] md:w-max'
              >
                {orderStep?.image?.url && (
                  <Image
                    src={orderStep?.image?.url}
                    alt={orderStep?.image?.alt || ''}
                    layout='fixed'
                    height={150}
                    width={150}
                    className='color-concept-print-gray hidden h-[80px] w-auto max-w-full md:block md:h-[100px] md:max-h-[150px] lg:h-[150px] lg:max-h-[150px]'
                  />
                )}
                {orderStep?.image?.url && (
                  <Image
                    src={orderStep?.image?.url}
                    alt={orderStep?.image?.alt || ''}
                    layout='fixed'
                    height={80}
                    width={80}
                    className='color-concept-print-gray block h-full max-h-[50px] w-auto max-w-full md:hidden md:max-h-[100px] lg:max-h-[150px]'
                  />
                )}
                <div className='md:Type-XL-Bold Type-Large-Bold flex w-full flex-col items-start justify-start text-concept-print-mediumgray md:ml-[30%]'>
                  <span>{`Step ${orderStep.index}:`}</span>
                  <span>{orderStep.label}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
