import { Image, StaticImport } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import { Card, Paper } from '@mui/material'
import icon from '../../assets/images/account-account-info-icon.svg'
import icon1 from '../../assets/images/home-customer-icon.svg'
import icon8 from '../../assets/images/home-paint-icon.svg'
import icon3 from '../../assets/images/home-protect-icon.svg'
import icon5 from '../../assets/images/home-recycle-icon.svg'
import icon2 from '../../assets/images/home-reorder-icon.svg'
import icon6 from '../../assets/images/home-shake-icon.svg'
import icon7 from '../../assets/images/home-store-icon.svg'
import icon4 from '../../assets/images/home-support-icon.svg'

export type IconDescriptionGrid = {
  icon: string | StaticImport
  details: string
}
export function WhyUseTempli() {
  const data: IconDescriptionGrid[] = [
    {
      icon: icon1,
      details: 'Customer obsessed support at every stage of your order.',
    },
    {
      icon: icon2,
      details: 'Easily re-order from your Orders dashboard.',
    },
    {
      icon: icon3,
      details: 'Highest quality standards for our products and print production.',
    },
    {
      icon: icon4,
      details: 'Speak with a production expert who help answer all your questions.',
    },
    {
      icon: icon5,
      details: 'Compostable and recyclable items that are easy on the planet.',
    },
    {
      icon: icon6,
      details: 'We’re trusted by design agencies who have seriously high quality standards.',
    },
    {
      icon: icon7,
      details: 'Check on your order status 24/7.',
    },
    {
      icon: icon8,
      details: 'Unlimited free proofs with every order.',
    },
  ]

  return (
    <div className='flex w-full items-center justify-center'>
      <Paper
        elevation={0}
        className='flex w-full max-w-maxContentWidth flex-col rounded-md bg-concept-print-lightgray/5 px-[20px] md:px-[160px]'
        sx={{
          backgroundColor: 'rgb(220 220 219 / 0.05)',
        }}
      >
        <div className='relative my-5 flex items-center lg:mx-0'>
          <span className='Type-XXL-Regular lg:Type-H2-Regular md:Type-H4-Regular text-concept-print-orange md:mx-4'>
            {i18n._('Why use Concept Print?')}
          </span>
        </div>{' '}
        <div className='mb-10 grid grid-cols-2 gap-5 gap-x-5 lg:grid-cols-4 lg:gap-10 lg:gap-x-10'>
          {data.map((item) => (
            <Card
              elevation={3}
              className='grid grid-cols-1 items-start justify-center justify-items-center gap-10 rounded-sm bg-pure-white p-5 py-10 shadow-sm'
            >
              <Image
                unoptimized
                src={item?.icon}
                height={50}
                width={50}
                layout='fixed'
                className='flex h-[50px] w-[50px] items-center justify-center'
              />
              <span className='text-center'>{item.details}</span>
            </Card>
          ))}
        </div>
      </Paper>
    </div>
  )
}
