import { Image } from '@graphcommerce/image'
import { Modal } from '@mui/material'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Icon } from '../Layout/Icon'
import { CarouselStateRaiser } from '../Product/ProductPageGallery'

export type ReviewImageGalleryModalProps = {
  images: any[]
  isDisplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  imageIndex?: number
}
export function ReviewImageGalleryModal(props: ReviewImageGalleryModalProps) {
  const { images, isDisplayed, setIsDisplayed, imageIndex } = props
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    setCurrentSlide(imageIndex ?? 0)
  }, [imageIndex])

  const handleClose = () => {
    setIsDisplayed(false)
    setCurrentSlide(0)
  }

  return (
    <Modal className='z-10 ' open={isDisplayed} onClose={handleClose}>
      <div className='absolute left-[5vw] top-[5vh] w-[90vw] overflow-auto rounded-md border-solid bg-pure-white pt-[24px] lg:left-[20vw] lg:w-[60vw]'>
        <div className='relative col-span-4 grid lg:order-last'>
          <CarouselProvider
            naturalSlideWidth={1.5}
            naturalSlideHeight={1}
            totalSlides={images.length}
            currentSlide={currentSlide}
            infinite
            className='self-center'
          >
            <Slider>
              <CarouselStateRaiser onSlideChange={setCurrentSlide} />
              {images.map((image, index) => (
                <Slide index={index}>
                  <div
                    key={image.original_url}
                    className='flex h-full max-h-full max-w-full cursor-pointer items-center justify-center rounded-[10px]'
                    role='button'
                    tabIndex={0}
                  >
                    <img src={image.original_url} />
                  </div>
                </Slide>
              ))}
            </Slider>
            <ButtonBack>
              <Icon
                name='chevron_left'
                className='absolute left-[20px] top-[calc(50%-15px)] z-10 cursor-pointer text-[40px] text-20-grey'
              />
            </ButtonBack>
            <ButtonNext>
              <Icon
                name='chevron_right'
                className='absolute right-[20px] top-[calc(50%-15px)] z-10 cursor-pointer text-[40px] text-20-grey'
              />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </Modal>
  )
}
