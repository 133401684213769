import { Image } from '@graphcommerce/image'
import { RowProductHighlightFragment } from './RowProductHighlight.gql'

type RowProductHighlightProps = RowProductHighlightFragment

export function RowProductHighlight(props: RowProductHighlightProps) {
  const { mainImage, mainLabel, mainImageMobile, secondaryImages } = props
  return (
    <div className='flex w-full items-center justify-center bg-pure-white'>
      <div className='grid-row lg:grid-col mx-5 mb-5 grid w-full max-w-maxContentWidth gap-y-2 lg:grid-cols-2 lg:gap-x-2'>
        <div
          className='relative hidden h-[300px] min-h-[300px] w-full bg-cover bg-center bg-no-repeat transition-all lg:block lg:h-full'
          style={{ backgroundImage: `url(${mainImage?.url})` }}
        >
          <h1 className='lg:Type-H2-Regular md:Type-H4-Regular Type-XXL-Regular flex h-full items-center justify-start p-10 text-concept-print-orange lg:absolute lg:top-0 lg:block lg:h-auto lg:pl-[5%] lg:pr-0 lg:pt-[5%]'>
            {mainLabel}
          </h1>
        </div>
        <div
          className='relative block h-[300px] min-h-[300px] w-full bg-cover bg-center bg-no-repeat transition-all lg:hidden lg:h-full'
          style={{ backgroundImage: `url(${mainImageMobile?.url})` }}
        >
          <h1 className='lg:Type-H2-Regular md:Type-H4-Regular Type-XXL-Regular flex h-full w-1/3 items-center justify-start p-10 text-concept-print-orange lg:absolute lg:top-0 lg:block lg:h-auto lg:pl-[5%] lg:pr-0 lg:pt-[5%]'>
            {mainLabel}
          </h1>
        </div>
        <div className='flex w-full flex-col gap-2 lg:flex'>
          {secondaryImages?.map((product) => (
            <div
              className='lg relative h-[300px] min-h-[300px] w-full bg-cover bg-center bg-no-repeat lg:h-[296px]'
              style={{ backgroundImage: `url(${product.image?.url || ''})` }}
              key={product.id}
            >
              <h1 className='lg:Type-H2-Regular md:Type-H4-Regular Type-XXL-Regular flex h-full w-1/3 items-center justify-start p-10 text-concept-print-orange lg:absolute lg:top-0 lg:block lg:h-auto lg:pl-[5%] lg:pr-0 lg:pt-[5%]'>
                {product.buttonLabel}
              </h1>
              {/* <a href={product.url || '#'}>
                <Button
                  label={product.buttonLabel}
                  color='blue'
                  type='button'
                  borderColor='white'
                  iconName='arrow_right_alt'
                  className='Type-Large-Bold absolute bottom-[10%] left-[10%] border-pure-white px-5 py-1 lg:bottom-[15%] lg:left-[25%]'
                />
              </a> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
