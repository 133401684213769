import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { Paper } from '@mui/material'
import PageLink from 'next/link'
import logoBlack from '../../../assets/images/concept-logo-black-bg.svg'
import logoWhite from '../../../assets/images/concept-logo-white.svg'

import logoTextWhite from '../../../assets/images/concept-name-white.svg'
import { Button } from '../../Button'
import { RowServicePageContentFragment } from './RowServicePageContent.gql'

type RowServicePageContentProps = RowServicePageContentFragment

export function RowServicePageContent(props: RowServicePageContentProps) {
  const { pageTitle, content } = props

  return (
    <div className='mx-[40px] my-[30px] shadow-xl md:mx-[15%] lg:mx-[20%]'>
      <div className='border-[1px] border-5-grey'>
        <h3 className='Type-H4-Bold flex w-full items-center justify-between py-[20px] pl-[20px] text-concept-print-black md:pl-[40px] lg:pl-[80px]'>
          {pageTitle}
        </h3>
        <div
          className='bg-[length:300px_300px] bg-center bg-repeat-space p-[20px] md:bg-[length:600px_600px] md:p-[40px] lg:p-[80px]'
          style={{ backgroundImage: `url(${logoBlack.src})` }}
        >
          <RichText raw={content?.raw} />
        </div>
      </div>
    </div>
  )
}
