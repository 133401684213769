import { ImageList, ImageListItem } from '@mui/material'
import { useState } from 'react'
import { ReviewImageGalleryModal } from './ReviewImageGalleryModal'

type ImageProps = {
  thumb_url: string
  original_url: string
}

type ReviewItemImageListProps = {
  images_data: ImageProps[]
  className: string
}

export function ReviewItemImageList(props: ReviewItemImageListProps) {
  const { className, images_data } = props
  const [isDisplayImageGallery, setIsDisplayImageGallery] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const handleClickImage = (index: number) => {
    setImageIndex(index)
    setIsDisplayImageGallery(true)
  }

  return (
    <>
      <ImageList variant='quilted' cols={3} rowHeight={100} className={className}>
        {images_data.map((item, index) => (
          <ImageListItem key={item.thumb_url} className='cursor-zoom-in'>
            <img
              src={`${item.thumb_url}?w=50&h=50&fit=crop&auto=format`}
              loading='lazy'
              onClick={() => handleClickImage(index)}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ReviewImageGalleryModal
        images={images_data}
        isDisplayed={isDisplayImageGallery}
        setIsDisplayed={setIsDisplayImageGallery}
        imageIndex={imageIndex}
      />
    </>
  )
}
