import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { BlogContent } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { RowBlogContentFragment } from './RowBlogContent.gql'

type RowBlogContentProps = RowBlogContentFragment

export function RowBlogContent(props: RowBlogContentProps) {
  const { content, htmlContent } = props
  const hasHtmlContent = htmlContent && htmlContent?.length > 0
  const hasContent = content?.raw && content?.raw?.children?.length > 0

  const [html, setHtml] = useState('')
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setHtml(htmlContent ?? '')
  }, [htmlContent])

  if (!content && !htmlContent) return null

  return (
    <BlogContent>
      {hasHtmlContent ? (
        <p dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        hasContent && (
          <RichText
            {...content}
            renderers={{
              image: ({ src, width, height, title, mimeType, sx }) => (
                <Box sx={sx}>
                  <Asset
                    asset={{ url: src, alt: title, width, height, mimeType }}
                    className='!w-auto'
                  />
                </Box>
              ),
            }}
          />
        )
      )}
    </BlogContent>
  )
}
